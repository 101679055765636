import { navigate as sr_navigate } from "svelte-routing";
import { getWeek, getWeekNumber, getWeekYear, WEEK_IN_MS } from "./week";
export const getFromEnv = (key) => {
    var _a;
    try {
        // @ts-ignore
        const { env } = process !== null && process !== void 0 ? process : {};
        const value = (_a = env[key]) !== null && _a !== void 0 ? _a : "";
        return String(value);
    }
    catch (_) {
        return "";
    }
};
const withoutTrailingSlash = (path) => path.endsWith("/") ? path.slice(0, -1) : path;
export const getBasePath = () => {
    const public_url = getFromEnv("PUBLIC_URL");
    const urlMatch = public_url.match(/\:\/\/[^/]+(.*)/);
    if (!urlMatch) {
        return withoutTrailingSlash(public_url);
    }
    return withoutTrailingSlash(urlMatch[1]);
};
export const withBasePath = (target) => {
    const prefix = target.startsWith("/") ? getBasePath() : "";
    return `${prefix}${target}`;
};
export const navigate = (target, options) => sr_navigate(withBasePath(target), options);
export const getWeekRoute = (year, weekNumber, i) => {
    const thursday = getWeek(year, weekNumber)[3];
    const relatedThursday = new Date(thursday.getTime() + i * WEEK_IN_MS);
    return `/${getWeekYear(relatedThursday)}/${getWeekNumber(relatedThursday)}`;
};
export const getYearRoute = (year, i = 0) => `/${year + i}`;
